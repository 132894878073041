import React from "react";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-lg",
};
const variants = {
  fill: {
    teal_700: "bg-teal-700 text-white-a700",
    teal_700_26: "bg-teal-700_26",
    gray_100: "bg-gray-100 text-teal-700_01",
    gray_50: "bg-gray-50 text-black-900",
  },
  outline: {
    teal_700_01: "border-teal-700_01 border border-solid",
  },
};
const sizes = {
  lg: "h-[46px] px-2.5 text-[16px]",
  xl: "h-[70px] px-[26px]",
  xs: "h-[38px] px-3.5 text-[14px]",
  md: "h-[42px] px-[34px] text-[16px]",
  sm: "h-[38px] px-3",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "md",
  color = "gray_50",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${shape && shapes[shape]} ${size && sizes[size]} ${variant && variants[variant]?.[color]}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round"]),
  size: PropTypes.oneOf(["lg", "xl", "xs", "md", "sm"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf(["teal_700", "teal_700_26", "gray_100", "gray_50", "teal_700_01"]),
};

export { Button };
