import React from 'react'
import { Input, Text } from './..'
export default function CustomInput({
  onChange,
  name,
  required = true,
  warn = false,
  type = 'email',
  label = 'Email Address',
  placeholder = 'Enter your email address in here',
  ...rProps
}) {
  const warnProps = { color: 'red_50', variant: 'fill', className: 'gap-[35px] font-bold border-red-700' }
  const props = warn ? warnProps : { className: 'self-stretch' }
  return (
    <div className="flex flex-col self-stretch items-start gap-2">
      <div className="flex gap-1 flex-wrap">
        <Text as="p" className="self-end tracking-[0.50px]">
          {label}
        </Text>
        {required && (
          <Text as="p" className="self-start !text-red-500 tracking-[0.50px]">
            *
          </Text>
        )}
      </div>
      <Input
        shape="round"
        type={type}
        placeholder={placeholder}
        className="self-stretch"
        onChange={onChange}
        name={name}
        {...props}
        {...rProps}
      />
    </div>
  )
}
