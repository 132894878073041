// Import JSON data
const productsData = require("../utils/products.json");
const categoriesData = require("../utils/categories.json");
const manufacturesData = require("../utils/brands.json");

// Convert product data to options format
const productsOptions = productsData.map((product) => ({
  label: product.name,
  value: product.name,
}));

// Convert categories data to options format
const categoriesOptions = categoriesData.map((category) => ({
  label: category.name,
  value: category.name,
}));

// Convert manufacturers data to options format
const brandsOptions = manufacturesData.map((manufacturer) => ({
  label: manufacturer.name,
  value: manufacturer.name,
}));

// Predefined radius options
const radiusOptions = [
  { label: "10 Miles", value: "10miles" },
  { label: "25 Miles", value: "25miles" },
  { label: "50 Miles", value: "50miles" },
  { label: "100 Miles", value: "100miles" },
  { label: "200 Miles", value: "200miles" },
  { label: "300 Miles", value: "300miles" },
  { label: "500 Miles", value: "500miles" },
];

// Doctor information list
const doctorInformationList = [
  {
    userName: "Thomas Charles Pitts, MD",
    userAddress: (
      <>
        251 E 33rd St
        <br />
        New York, NY 10016
      </>
    ),
    userSpeciality: "Neurology",
    specialityLabel: "Speciality",
    distance: "0.21 mi",
  },
  {
    userName: "Thomas Charles Pitts, MD",
    userAddress: (
      <>
        251 E 33rd St
        <br />
        New York, NY 10016
      </>
    ),
    userSpeciality: "Neurology",
    specialityLabel: "0.21 mi",
    distance: "0.21 mi",
  },
  {
    userName: "Thomas Charles Pitts, MD",
    userAddress: (
      <>
        251 E 33rd St
        <br />
        New York, NY 10016
      </>
    ),
    userSpeciality: "Neurology",
    specialityLabel: "0.21 mi",
    distance: "0.21 mi",
  },
];

const dropdownConfigs = [
  {
    name: "product",  // Changed to lowercase to match the filter state
    placeholder: "Search Product",
    options: productsOptions,
  },
  {
    name: "category",  // Changed to lowercase to match the filter state
    placeholder: "Select Category",
    options: categoriesOptions,
  },
  {
    name: "brand",  // Changed to lowercase to match the filter state
    placeholder: "Select Brand",
    options: brandsOptions,
  },
];


// Export all the constants
export {
  dropdownConfigs,
  radiusOptions,
  doctorInformationList,
};
