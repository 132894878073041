// import { Heading, Img, ChipView, Button } from './..'
import React, { useState } from 'react'
import { Button, Text, Img, Heading, ChipView } from '../'
import { default as ModalProvider } from 'react-modal'
import CustomInput from 'components/CustomInput'

function EmailModal({ isShowModal, onClose, onSubmit, item_id }) {
  const [email, setEmail] = useState('')

  const handleSubmit = () => {
    if (email) {
      onSubmit(email) // Call the submit handler with the email
    } else {
      alert('Please enter a valid email')
    }
  }
  return (
    <ModalProvider
      isOpen={isShowModal}
      appElement={document.getElementById('root')}
      className="fixed inset-0 flex items-center justify-center z-50"
      // overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-a700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Contact Info
        </Text>
        <div className="w-full mb-3">
          <CustomInput label="Email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="flex justify-between w-full gap-4">
          <Button shape="round" className="tracking-[1.25px] uppercase font-medium min-w-[150px]" onClick={onClose}>
            Cancel
          </Button>
          <Button
            // variant="gradient"
            shape="round"
            color="teal_700"
            className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </ModalProvider>
  )
}

export default function UserProfile({ title, address, item_id, ...props }) {
  const [chipOptions] = React.useState([
    { value: 1, label: `Open Mon - Fri` },
    { value: 2, label: `Open Sundays` },
  ])
  const [showModal, setShowModal] = useState(false) // State for modal
  const [email, setEmail] = useState('')

  const handleClickInfo = () => {
    setShowModal(true) // Show modal when button is clicked
  }

  const handleModalClose = () => {
    setShowModal(false) // Close modal
  }

  const handleModalSubmit = (email) => {
    setEmail(email)
    console.log(`Item ID: ${item_id}, Email: ${email}`)
    setShowModal(false) // Close modal after submission
  }

  return (
    <div
      {...props}
      className={`${props.className} flex px-3 py-4 border border-black-900_19 border-solid flex-1 rounded-[12px] bg-white-a700 bg-opacity-80`}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-1 flex-col gap-3 sm:gap-3">
          <div className="flex justify-between">
            <Heading size="headingmd" as="h4" className="leading-[150%] sm:text-[20px]">
              {title}
            </Heading>
            <Button
              color="teal_700_01"
              size="sm"
              variant="outline"
              shape="round"
              className="relative z-[1] mr-3 w-[38px] sm:mr-0"
            >
              <Img src="images/img_arrow_left.svg" />
            </Button>
          </div>
          <Heading as="h6" className="!font-semibold leading-[21px] !text-gray-900 sm:text-[13px]">
            {address}
          </Heading>
        </div>
        <div className="flex flex-col mt-2">
          <ChipView
            options={chipOptions}
            // setOptions={setChipOptions}
            // values={selectedChipOptions}
            // setValues={setSelectedChipOptions}
            className="mb-2 flex flex-1 flex-grow gap-2"
          >
            {(option) => (
              <React.Fragment key={option.index}>
                <div
                  onClick={option.toggle}
                  className="flex h-[26px] min-w-[134px] cursor-pointer flex-row items-center justify-center gap-4 rounded-lg bg-gray-300 px-2.5 text-center text-[12px] font-medium"
                >
                  <Img src="images/img_iontimeoutline.svg" alt="Ion:time-outline" className="h-[16px] w-[16px]" />
                  <span>{option.label}</span>
                </div>
              </React.Fragment>
            )}
          </ChipView>
          <Button
            color="teal_700"
            className="self-end tracking-[1.25px] rounded-lg"
            size="sm"
            onClick={handleClickInfo}
          >
            Get Contact Info
          </Button>
        </div>
      </div>
      {showModal && (
        <EmailModal isShowModal={showModal} onClose={handleModalClose} onSubmit={handleModalSubmit} item_id={item_id} />
      )}
    </div>
  )
}
