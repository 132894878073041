import React, { useState, useCallback, useEffect } from 'react'
import { GoogleMap, Marker, Circle, InfoWindow } from '@react-google-maps/api' // Revert to Marker
import { Spinner } from 'components'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
// FIXME info window marker click not working
const GoogleMapWrapper = ({ records, location, radius }) => {
  console.info(records,location,radius)


  const [map, setMap] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null) // Track the selected location (lat, lng)
  const [selectedTitles, setSelectedTitles] = useState([]) // Store titles for markers at the selected location
  const [markerClusterer, setMarkerClusterer] = useState(null)

  const onLoad = useCallback((map) => {
    setMap(map)

    const clusterer = new MarkerClusterer({
      map,
      markers: [],
      options: {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      },
    })

    setMarkerClusterer(clusterer)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null)
    if (markerClusterer) {
      markerClusterer.clearMarkers()
    }
  }, [markerClusterer])

  useEffect(() => {
    if (markerClusterer && records.length > 0) {
      const markers = records.map((item) => {
        return new window.google.maps.Marker({
          position: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) },
          title: item.title,
        })
      })

      markerClusterer.clearMarkers()
      markerClusterer.addMarkers(markers)
    }
  }, [markerClusterer, records])

  // Handle marker click to show InfoWindow with all titles at the clicked location
  const handleMarkerClick = (clickedMarker) => {
    const clickedLat = parseFloat(clickedMarker.latitude)
    const clickedLng = parseFloat(clickedMarker.longitude)
    console.log('Marker Clicked:', clickedMarker) // Debugging line

    // Find all markers at the same coordinates
    const markersAtSameLocation = records.filter(
      (marker) => 
        parseFloat(marker.latitude) === clickedLat && 
        parseFloat(marker.longitude) === clickedLng
    )

    // Update selected location and titles for InfoWindow
    setSelectedLocation({ lat: clickedLat, lng: clickedLng })
    setSelectedTitles(markersAtSameLocation.map((marker) => marker.title))
  }
  const centerLocation={lat:location.lat,lng:location.lng}
  return true ? (
    <GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%' }}
      center={location.zipCode ? centerLocation : { lat: records[0].latitude, lng: records[0].longitude }}
      zoom={8}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {location.zipCode && radius && (
        <Circle
          center={centerLocation}
          radius={radius}
          options={{
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
          }}
        />
      )}

      {/* Display Markers */}
      {records.map((item, index) => (
        <Marker
          key={index} // Use `Marker` instead of `MarkerF`
          position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
          title={item.title}
          onClick={() => {
            console.log("Marker Clicked:", item); // Debugging log
            handleMarkerClick(item); // Handle marker click
          }}
        />
      ))}

      {/* InfoWindow showing titles of markers at the selected location */}
      {selectedLocation && (
        <InfoWindow
          position={selectedLocation}
          onCloseClick={() => setSelectedLocation(null)}
        >
          <div>
            <h4>Markers at this location:</h4>
            <ul>
              {selectedTitles.map((title, index) => (
                <li key={index}>{title}</li>
              ))}
            </ul>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <Spinner />
  )
}

export { GoogleMapWrapper }
