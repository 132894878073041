// Declare constants and functions
export const milesToKilometers = (miles) => {
  return miles * 1.60934;
};

function getRadius(val) {
  if (!val) return;
  let r = parseInt(val.replace(" Miles", "").trim());
  return milesToKilometers(r) * 1000;
}

// Exporting everything
export {
  getRadius,
};
