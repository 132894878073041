import { Helmet } from 'react-helmet'
import UserProfile from '../components/UserProfile'
import React, { Suspense, useEffect, useState } from 'react'
import { Button, Img, SelectBox, Heading, Input, Spinner } from '../components'
import { dropdownConfigs, radiusOptions } from './data'
import { CSSTransition } from 'react-transition-group'
import './AstheticLocator50Page.css' // Import the CSS file for transitions
import { toast } from 'react-toastify'
import { getRecords } from 'api/record'
import axios from 'axios'
import { GOOGLE_MAP_KEY } from 'config/config'
import { GoogleMapWrapper } from 'components/GoogleMap'
import { Autocomplete, LoadScript, useJsApiLoader } from '@react-google-maps/api'
import GoogleAutocomplete from 'components/GoogleAutocomplete'
import { getRadius } from 'utils/utils'
export default function AstheticLocator50Page() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: ['places'],
  })
  const [filters, setFilters] = useState({ product: '', brand: '', category: '' })
  const [location, setLocation] = useState({ lat: null, lng: null, zipCode: '', address: '' })

  const [showMap, setShowMap] = useState(false)
  const [radius, setRadius] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const handleClear = () => {
    // Reset filters to default
    setFilters({
      product: '',
      brand: '',
      category: '',
    })

    // Reset location to default
    setLocation({
      lat: null,
      lng: null,
      zipCode: '',
      address: '',
    })
  }
  // FIXME: radius not clearing

  const handleSearch = async () => {
    const { product, brand, category } = filters
    console.log(filters, location)
    if (location.zipCode && !radius) {
      toast.warn('Please select a radius when selecting a location.')
      return
    }

    // Check if no filters (product, brand, category) are selected and no location or radius
    if (!product && !brand && !category && !location.zipCode && !radius) {
      toast.warn('Please select at least one filter or provide a valid location and radius.')
      return
    }

    const activeFilters = [product, brand, category].filter(Boolean)

    // Check if more than one filter is selected
    if (activeFilters.length > 1) {
      toast.warn('Only one filter is allowed at a time.')
      return
    }

    // Determine which filter is selected and extract only its value
    let selectedFilterKey = null
    let selectedFilterValue = ''

    if (product) {
      selectedFilterKey = 'product'
      selectedFilterValue = product.value // Assuming product is { label: '', value: '' }
    } else if (brand) {
      selectedFilterKey = 'brand'
      selectedFilterValue = brand.value // Assuming brands is { label: '', value: '' }
    } else if (category) {
      selectedFilterKey = 'category'
      selectedFilterValue = category.value // Assuming category is { label: '', value: '' }
    }

    setLoading(true)

    try {
      let queryParams = {}

      // If a filter is selected, add it to the queryParams
      if (selectedFilterKey && selectedFilterValue) {
        queryParams[selectedFilterKey] = selectedFilterValue
      }
      if (location.zipCode && radius) {
        queryParams.zipCode = location.zipCode
        queryParams.radius = getRadius(radius)
      }
      const resp = await getRecords(queryParams)
      if (resp.records.length === 0) {
        toast.warn('No nearby ZIP codes found. Please select a different ZIP code or increase the radius.')
        setLoading(false)
        return
      }
      setData(resp.records)
      setShowMap(true)
    } catch (error) {
      toast.error(error.response?.data?.error || 'Error fetching records.')
    }

    setLoading(false)
  }

  const handleBackToFilters = () => {
    setShowMap(false)
  }
  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value, // Now we're only storing the value, no need for { label, value }
    }))
  }
  useEffect(() => {
    const activeFilters = Object.entries(filters).filter(([key, value]) => value !== '')
    if (activeFilters.length > 1) {
      const [activeField, activeValue] = activeFilters[activeFilters.length - 1] // The latest change
      setFilters({
        [activeField]: activeValue, // Keep only the latest selection
        product: activeField === 'product' ? activeValue : '',
        category: activeField === 'category' ? activeValue : '',
        brand: activeField === 'brand' ? activeValue : '',
      })
      // Show a warning toast
      toast.warn('You can only select one filter at a time!')
    }
  }, [filters])

  return (
    <>
      <Helmet>
        <title>Find Aesthetic Treatments Near You</title>
        <meta
          name="description"
          content="Looking for medical specialists in your area? Use our locator to find neurology experts like Thomas Charles Pitts, MD, and others within a 10-mile radius. Contact us for more information."
        />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-custom overflow-auto">
        {isLoaded ? (
          <>
            <CSSTransition in={!showMap} timeout={300} classNames="fade" unmountOnExit>
              <div className="h-screen w-full flex flex-col">
                {/* Filter Section */}
                <div className="mt-2 flex flex-col items-center px-2 w-full">
                  <div className="flex items-center justify-between gap-5 rounded-[12px] bg-white-a700_a5 px-[36px] py-2.5 w-full">
                    <Img
                      src="images/img_image_1.png"
                      alt="Featured Image"
                      className="h-[8vh] w-[24%] object-contain sm:w-[70%]"
                    />
                    <Button
                      color="teal_700"
                      className="mr-2 rounded-[12px] uppercase sm:hidden"
                      onClick={() => window.open('https://app.aestheticlocator.com/', '_blank')}
                    >
                      Database Access
                    </Button>
                    <img src="images/img_link.svg" alt="Edit" className="min-w-[22px] hidden sm:flex" onClick={() => window.open('https://app.aestheticlocator.com/', '_blank')}/>
                  </div>
                  <div className="w-[75%] mt-[82px] sm:w-[95%] sm:mt-[22px] sm:bg-white-a700">
                    <div className="mx-auto flex w-full flex-col items-start gap-6 px-10">
                      <Heading
                        size="headinglg"
                        as="h1"
                        className="sm:mt-[22px] sm:!text-[#1c9368] w-[40%] leading-[150%] !text-white-a700 sm:w-full sm:text-[2.2rem] md:w-full"
                      >
                        Find Aesthetic Treatments Near You
                      </Heading>

                      <Heading
                        size="textlg"
                        as="h2"
                        className="sm:!text-[#1c9367a0]  ml-2.5 leading-[150%] !text-white-a700 sm:ml-0 sm:text-base md:ml-0"
                      >
                        <>
                          Search our Aesthetic database
                          <br />
                          for your favorite treatments, brands and more!
                        </>
                      </Heading>
                    </div>

                    <div className="mt-[50px] flex justify-center rounded-[16px] bg-white-a700 p-3">
                      <div className="mb-3 flex w-full flex-col gap-2">
                        <div className="flex flex-col">
                          <div className="flex gap-3 sm:flex-col md:flex-col items-center">
                            <div className="lg:w-1/2 w-full">
                              <GoogleAutocomplete location={location} setLocation={setLocation} />
                            </div>
                            <SelectBox
                              shape="round"
                              indicator={
                                <Img
                                  src="images/img_ouiarrowdown.svg"
                                  alt="Oui:arrow-down"
                                  className="h-[16px] w-[16px]"
                                />
                              }
                              name="Radius"
                              placeholder="Search Radius"
                              options={radiusOptions}
                              className="lg:w-1/2 w-full"
                              onChange={(v) => setRadius(v.label)}
                            />
                            <div className='flex gap-2'>
                            <Button
                              className="mr-2  rounded-[6px] uppercase md:mr-0 bg-gray-300"
                              onClick={handleClear}
                              disabled={loading}
                            >
                              Clear
                            </Button>
                            <Button
                              color="teal_700"
                              className={`mr-2  rounded-[6px] uppercase md:mr-0 ${
                                loading ? 'opacity-50 cursor-not-allowed' : ''
                              }`}
                              onClick={handleSearch}
                              disabled={loading}
                            >
                              {loading ? <Spinner /> : 'Search'}
                            </Button>
                            </div>
                          </div>

                          <div className="flex flex-col my-2">
                            <div className="mx-[90px] flex items-center justify-between gap-4 md:mx-0 my-2">
                              <div className="flex-1 h-[1px] bg-blue_gray-100" />
                              <Heading size="textxs" as="h3" className="!font-light !text-black-900 mx-4">
                                Select One Of Three
                              </Heading>
                              <div className="flex-1 h-[1px] bg-blue_gray-100" />
                            </div>
                            <div className="flex gap-3 sm:flex-col md:flex-col">
                              {dropdownConfigs.map((config, index) => (
                                <SelectBox
                                  key={index}
                                  name={config.name}
                                  placeholder={config.placeholder}
                                  options={config.options}
                                  className="w-full gap-1"
                                  value={filters[config.name]} // Bind value to state
                                  onChange={(value) => handleFilterChange(config.name, value)} // Directly pass the value
                                />
                              ))}
                            </div>
                          </div>
                          <div class="flex">
                            <a
                              className="flex items-center justify-center text-[#00945b] font-medium text-sm group-hover:text-teal-700"
                              href="https://tally.so/r/3jLEqx"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="images/img_link.svg" alt="Edit" className="min-w-[22px]" />
                              Are We Missing A Brand or Product? Suggest An Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>

            <CSSTransition in={showMap} timeout={300} classNames="fade" unmountOnExit>
              <div className="flex w-full h-screen flex-col items-center justify-center">
                {/* Map Section */}
                <div className="relative w-full flex-1 flex flex-col items-center justify-center">
                  <GoogleMapWrapper
                    className="mx-auto h-full w-full rounded-lg"
                    records={data}
                    location={location}
                    radius={getRadius(radius)}
                  />
                  <Button
                    color="teal_700"
                    className="absolute top-4 right-4 rounded-full"
                    onClick={handleBackToFilters}
                  >
                    Back to Filters
                  </Button>
                </div>
                <div className=" flex items-center md:px-5 absolute bottom-10 sm:bottom-0">
                  <div className="flex gap-4 mx-10 sm:flex-col md:flex-col">
                    <Suspense fallback={<div>Loading feed...</div>}>
                      {data.map((d, index) => (
                        <UserProfile {...d} key={'listArrows' + index} />
                      ))}
                    </Suspense>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  )
}
