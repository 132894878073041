import axios from "axios";
import { BACKEND_URL } from "config/config";

const VERSION = "v1";

const getRecords = async ( filters = {}) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/api/${VERSION}/public/records`, {
      params: {
        ...filters, // Pass the filters as query params
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching records:", error);
    throw error;
  }
};

export { getRecords };
